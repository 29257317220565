:root {
	--bg_main: #ebebeb;
	--bg_panel: #fcfcfc;
	--fg_primary: #1259aa;
	--fg_secondary: #445579;
	--fg_comment: #a1a1a1;
	--fg_border: #c4c4c4;
	--fg_bordergray2: #ececec;
    --fg_shadow: rgba(0,0,0,0.1);
}

[data-theme='dark'] {
	--bg_main: #0c0c0c;
	--bg_panel: #23252e;
	--fg_primary: #f5ffc7;
	--fg_secondary: #a4b0ca;
	--fg_comment: #868686;
	--fg_border: #474747;
	--fg_bordergray2: #ececec;
    --fg_shadow: rgba(220,220,220,0.2);
}

[data-theme='day'] {
	--bg_main: #103858;
	/*--bg_main: linear-gradient(0deg, rgba(38,39,136,1) 0%, rgba(77,72,175,1) 31%, rgba(3,0,98,1) 100%);*/
	--bg_panel: #1f294d;
	--fg_primary: #dbeeb7;
	--fg_secondary: #9ba091;
	--fg_comment: #a1a1a1;
	--fg_border: #7d358b;
	--fg_bordergray2: #5b527c;
    --fg_shadow: rgba(119,191,197,0.2);
}

[data-theme='night'] {
	--bg_main: #0c0c0c;
	--bg_panel: #23252e;
	--fg_primary: #9aa371;
	--fg_secondary: #6c7485;
	--fg_comment: #868686;
	--fg_border: #474747;
	--fg_bordergray2: #ececec;
    --fg_shadow: rgba(220,220,220,0.1);
}
