@import "sh_colors.css";

body {
  background-color: var(--bg_main);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
  font-family: Arial, Verdana, sans-serif;
  color: var(--fg_primary);
  overflow: hidden; 
  margin: 0px;
}

div.App {
  margin: 0px;
}