@import "sh_colors.css";

/* alap elemek */

div.pageDiv {
	width: 100vw;
	height: 100vh;
    display: flex;
	flex-direction: column;
	background-color: var(--bg_main);
	vertical-align: middle;
}

div.sensorDiv {
	width: 100vw;
	height: 90vh;
    display: flex;
	flex-direction: row;
	background-color: var(--bg_main);
	vertical-align: middle;
}

div.sensorlista {
    display: flex;
    flex-wrap: wrap;
	background: var(--bg_main);
	padding-bottom: 6px;
	justify-content: space-evenly;
	align-content: center;
	padding: 1vmin;
}

div.sensorpanel {	
    display: flex;
	width: 52vmin;
	height: 23vmin;
	margin: 0px;
	margin-left: 4px;
	margin-bottom: 4px;
    padding: 2vmin;
	background: var(--bg_panel);
	border-style: hidden;
	border-color: var(--fg_border);
	border-radius: 6px;
	box-shadow: 0px 0px 2px 2px var(--fg_shadow);
	border-collapse: collapse;
    white-space: nowrap;
    font-size: 12vmin;
    font-weight: bold;
	color: var(--fg_primary);
}

div.sensorpanel_small {	
    display: flex;
	width: 36vmin;
	height: 12vmin;
	margin: 0px;
	margin-left: 4px;
	margin-bottom: 10px;
    padding: 2vmin;
	background: var(--bg_panel);
	border-style: hidden;
	border-color: var(--fg_border);
	border-radius: 6px;
	box-shadow: 0px 0px 2px 2px var(--fg_shadow);
	border-collapse: collapse;
    white-space: nowrap;
    font-size: 10vmin;
    font-weight: bold;
	color: var(--fg_primary);
}

div.sensorimage {
    display: flex;
    flex-direction: column;
	width: 12vmin;
    font-size: 2vmin;
    padding-right: 1vw;
    justify-content: center;
	align-items: center;
    color: var(--fg_comment);
}

div.sensorimage img {
  float: left;  
  vertical-align: middle;
  max-width: 20vmin;
  height: auto;
}

div.sensorimage_small {
    display: flex;
    flex-direction: column;
	width: 12vmin;
    font-size: 2vmin;
    padding-right: 1vw;
    justify-content: center;
	align-items: center;
    color: var(--fg_comment);
}

div.sensorimage_small img {
  float: left;  
  vertical-align: middle;
  max-width: 8vmin;
  height: auto;
}

div.sensorvalues {
    display: flex;
	flex-direction: column;
	justify-content: center;
	width: 32vw;
}

div.sensorvalues_small {
    display: flex;
	flex-direction: column;
	justify-content: center;
	width: 32vw;
}


div.sensorvalue {
    font-size: 14vmin;
	display: flex;
	align-items: baseline;
	justify-content: flex-end;
}

div.sensorvalue_small {
    font-size: 8vmin;
	display: flex;
	align-items: baseline;
	justify-content: flex-end;	
}

div.sensorvalue2 {
    font-size: 4vmin;
    color: var(--fg_secondary);
	text-align: right;
}

div.sensorvalue2_small {
    font-size: 4vmin;
    color: var(--fg_secondary);
	text-align: right;
}

div.fracpart {
    display: inline-flex;
    font-style: normal;
    font-size: 70%;
}

div.timeheader {
	display: flex;
	color: var(--fg_primary);
	align-content: center;
	justify-content: space-between;
	height: 10vh;
	background-color: var(--bg_main);
	font-size: 5vw;
	font-weight: bold;
	padding: 1vmin;
}

/* új panel elosztás */

div.importants {
	display: flex;
	flex-direction: column;
	width: 32vw;
}

div.nonimportants {
	display: flex;
	flex-direction: column;
	width: 28vw;
}

div.weathercol {
	display: flex;
	flex-direction: column;
	width: 35vw;
}

div.others {
	display: flex;
	flex-direction: column;
	width: 26vw;
}


div.panel_important {	
    display: flex;
	/*width: 40vmin;*/
	height: 20vh;
	margin: 0px;
	margin-left: 10px;
	margin-bottom: 10px;
    padding: 2vmin;
	background: var(--bg_panel);
	border-style: hidden;
	border-color: var(--fg_border);
	border-radius: 6px;
	box-shadow: 0px 0px 2px 2px var(--fg_shadow);
	border-collapse: collapse;
    white-space: nowrap;
    font-size: 12vmin;
    font-weight: bold;
	color: var(--fg_primary);
}

div.panel_nonimportant {	
    display: flex;
	/*width: 40vmin;*/
	height: 20vh;
	margin: 0px;
	margin-left: 6px;
	margin-bottom: 6px;
    padding: 2vmin;
	background: var(--bg_panel);
	border-style: hidden;
	border-color: var(--fg_border);
	border-radius: 6px;
	box-shadow: 0px 0px 2px 2px var(--fg_shadow);
	border-collapse: collapse;
    white-space: nowrap;
    font-size: 8vmin;
    font-weight: bold;
	color: var(--fg_primary);
}

/* weather */

#koponyegframe {
	zoom: 1.0;
	transform: scale(1.0);
	transform-origin: 0 0;
	-moz-transform: scale(1.0);
	-moz-transform-origin: 0 0;
	-o-transform: scale(1.0);
	-o-transform-origin: 0 0;
	-webkit-transform: scale(1.0);
	-webkit-transform-origin: 0 0;
  }

  div.warningpanel {	
    display: flex;
	width: 60vmin;
	height: 34vmin;
	margin: 0px;
	margin-left: 4px;
	margin-bottom: 10px;
    padding: 1vmin;
	background: var(--bg_panel);
	border-style: hidden;
	border-color: var(--fg_border);
	border-radius: 6px;
	box-shadow: 0px 0px 2px 2px var(--fg_shadow);
	border-collapse: collapse;
    white-space: nowrap;
    font-size: 3vmin;
    /*font-weight: bold;*/
	color: var(--fg_primary);
}

/*
div.rw-container {
	height: 20vh;
}

div.rw-container-main {
	height: 10vh;
}

div.rw-container-left {
	height: 10vh;
}

div.rw-container-right {
	height: 10vh;
}

.rw-container-header {
	font-size: 10vw;
}

div.rw-today {
	visibility: hidden;
}
*/
